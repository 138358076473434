<script lang="ts" setup>
import type { HomeGamesList, GamesFilters } from "@/types";

withDefaults(
	defineProps<{
		type?: "slider_vertical" | "slider_horizontal";
		games: HomeGamesList[] | HomeGamesList | GamesFilters;
		title: string;
		showAll?: boolean;
		hideFavoriteBtn?: boolean;
		icon?: string;
		isAdminIcon?: boolean;
	}>(),
	{
		showAll: false
	}
);

defineEmits<{ (e: "viewAllAction"): void }>();

const { isMobile } = useDevice();
const { t } = useT();
const { handleToggleToFavoriteClick } = useAddGameToFavorite({ t, toastTheme: "dark", toastPosition: "bottom-center" });

const cardSpacing = computed(() => (isMobile ? 12 : 16));
</script>

<template>
	<div>
		<MSliderWrapper
			:list="games as []"
			:title="title"
			:showAll="showAll"
			:icon="icon || ''"
			:isAdminIcon="isAdminIcon"
			:spacing="cardSpacing || 12"
			@view-all-action="$emit('viewAllAction')"
		>
			<template #default="{ item }">
				<div class="keen-slider__slide">
					<MGameType
						v-for="(game, index) in Array.isArray(item) ? item : [item]"
						:key="index"
						:game="game"
						:type="type"
						@toggle-favorite="handleToggleToFavoriteClick"
					/>
				</div>
			</template>
		</MSliderWrapper>
	</div>
</template>

<style lang="scss" scoped>
.keen-slider__slide {
	display: flex;
	flex-direction: column;
	gap: 12px;

	@include media-breakpoint-up(lg) {
		gap: 16px;
	}
}
</style>
<style lang="scss">
.Toastify__toast-container--bottom-center {
	@include media-breakpoint-up(lg) {
		left: auto;
		right: 16px;
		transform: translateX(0%);
	}
}
</style>
